<template>
        <div class="market">
            <div class="header-table">
                <span style="text-align:start"> مجموع </span>
                <span style="text-align:center"> مقدار </span>
                <span style="text-align:end"> زمان </span>
            </div>
            <div class="body-table">
                <div v-for="(trade,index) in lastTrades" :key="index" class="coin-row">
                    <span style="text-align:start" class="success" v-if="trade.orderType.includes('BUY')"> {{$route.params.tradeTo=='TETHER'?$toLocal(trade.wholePrice,2) : $toLocal(trade.calculatedPriceInTomanMarket,0)}}</span>
                    <span style="text-align:start" class="fail" v-if="trade.orderType.includes('SELL')"> {{$route.params.tradeTo=='TETHER'?$toLocal(trade.wholePrice,2) : $toLocal(trade.calculatedPriceInTomanMarket,0)}}</span>
                    <span style="text-align:center"> {{ $toLocal(trade.amount,$decimal[$route.params.tradeFrom]) }} </span>
                    <span style="text-align:end">{{$G2J(trade.createdAtDateTime,'time')}}</span>
                </div>
            </div>
        </div>
</template>
<script>
import {Loop} from '../../library/reuseableFunction';
export default {
    name:'LastTrades',
    data() {
        return {
            lastTrades:[],
            loop:''
        }
    },
    methods:{
        async getLastTrades(){
            const res = await this.$axios.get('/trades/last-trades', {
                    params: {
                        marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        size: 20,
                        type: 'P2P'
                    }
                })
            this.lastTrades=res.content
        },
        beforeLoop() {
                this.state.loading = true
                if (this.state.loop) {
                    this.loop = new Loop(this.getLastTrades, 5000, 'lastTrades')
                    this.loop.start()
                } else {
                    this.getLastTrades()
                }
        },
    },
    mounted() {
        this.beforeLoop();

    },
    beforeDestroy() {
        if(this.loop){
                    this.loop.stop()
            }
    },
}
</script>

<style lang="scss" scoped>
.market{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 32%;
    max-height: 300px;
    .header-table{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        font-size: clamp(12px,1vw,14px);
        padding: 12px 12px 4px;
        color: var(--grey);
        span{
                width: 100px;
                text-align: center;
            }

    }
    .body-table{
        display: flex;
        flex-direction: column;
        padding: 0px 0px 12px;
        font-size: clamp(10px,1vw,12px);
        row-gap: 4px;
        background: white;
        overflow: scroll;
        max-height: 100%;
        img{
            width: 26px;
            height: 26px;
        }
        span{
            font-weight: 400;
            font-size: clamp(14px,1vw,12px);
        }
        .coin-row{
            padding: 4px 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            span{
                text-align: center;
                width: 100px;
            }
            .coin{
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 8px;
                width: 120px;
            }
        }
    }
}
.selectedprice{
    background: var(--pricetype-yellow) !important;
    color: var(--dark-primary) !important;
}
.coinslist{
    width: 39%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

}
.titles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pricebtns{
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background: white;
        padding: 4px 8px;
        width: 20%;
        button{
            width: 50%;
            background: transparent;
            border-radius: 4px;
            color: var(--grey);
            // padding: 4px 8px;
        }
    }
}
@media only screen and(max-width:1700px){
    .body-table{
        overflow: scroll;
        max-height: 210px;
    }
}
@media only screen and(max-width:1000px){
    .market{
        width: 100%;
    }
}
</style>