<template>
    <div class="coinslist">
        <div class="titles">
            <span class="tradestitle"> بازار ها </span>
            <div class="pricebtns">
                <button @click.prevent="$router.push('/advancetrade/TOMAN/'+$route.params.tradeFrom)" :class="{'selectedprice':$route.params.tradeTo=='TOMAN'}">تومان</button>
                <button @click.prevent="$router.push('/advancetrade/TETHER/'+$route.params.tradeFrom)" :class="{'selectedprice':$route.params.tradeTo=='TETHER'}" > تتر </button>
            </div>
        </div>
        <div class="market">
            <div class="header-table">
                <span style="width:120px"> بازار </span>
                <span> قیمت واحد </span>
                <span> تغییرات </span>
            </div>
            <div class="body-table">
                <div @click="reDirect($coin2Snake[coin.sourceCoinSymbol])" v-for="(coin,index) in coins" :key="index" class="coin-row">
                    <div class="coin">
                        <img class="coin-icon" :src="'/coins/' +$coin2Snake[coin.sourceCoinSymbol]+ '.png'" alt="">
                        <span> {{$coinLabel[coin.sourceCoinSymbol]}} </span>
                    </div>
                    <span> {{$toLocal(coin.price,$decimal[$route.params.tradeTo])}} </span>
                    <span class="list-success success" v-if="coin.price24hrChangePercent > 0"> {{$toLocal(coin.price24hrChangePercent,2)}}%+</span>
                    <span class="list-fail fail" v-if="coin.price24hrChangePercent < 0"> {{$toLocal(Math.abs(coin.price24hrChangePercent),2)}}%- </span>
                    <span class="list-fail fail" v-if="coin.price24hrChangePercent == 0"> 0 </span>
                </div>
            </div>
        </div>
        <span class="tradestitle"> آخرین معاملات </span>
    </div>
</template>
<script>
export default {
    name:'CoinList',
    data() {
        return {
            coins:[]
        }
    },
    methods:{
        reDirect(e){
            this.$router.push('/advancetrade/'+this.$route.params.tradeTo+'/'+e)
        },
        async getCoins(){
            const res = await this.$axios.get(this.$route.params.tradeTo=='TOMAN' ? '/coins/price/usd' : '/coins/price/usdt',{
                        params:{
                            include24hrChange: true,
                            includeWeeklyChange: false
                        }
                    });
            // if(this.$route.params.tradeTo=='TETHER'){
            //     this.coins=res.filter(a => a.destCoinSymbol !== 'TETHER');
            // }else{
                this.coins=res
            // }
        },
    },
    mounted() {
        this.getCoins()
    },
    beforeDestroy() {
            if(this.loop){
                    this.loop.stop()
            }
    },
}
</script>

<style lang="scss" scoped>
.tradestitle{
    font-weight: 400;
    font-size: clamp(14px,1vw,12px);
    color: var(--black-fee);
}
.list-success{
    background-color: rgba(202, 235, 199, 1) ;
    padding: 2px 4px;
    border-radius: 2px;

}
.list-fail{
    background-color: rgba(255, 228, 228, 1) ;
    padding: 2px 4px;
    border-radius: 2px;

}
.market{
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 4px;
    overflow: hidden;
    .header-table{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 12px 12px 4px;
        font-size: clamp(12px,1vw,14px);
        color: var(--grey);

    }
    .body-table{
        display: flex;
        flex-direction: column;
        padding: 0px 0px 12px;
        row-gap: 4px;
        background: white;
        font-size: clamp(10px,1vw,12px);
        height: 100%;
        overflow: scroll;
        img{
            width: 26px;
            height: 26px;
        }
        span{
            font-weight: 400;
            font-size: clamp(14px,1vw,12px);
        }
        .coin-row{
            cursor: pointer;
            padding: 4px 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            .coin{
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 8px;
                width: 120px;
            }
        }
    }
}
.selectedprice{
    background: var(--pricetype-yellow) !important;
    color: var(--dark-primary) !important;
    border-radius: 4px;
}
.coinslist{
    height: 100%;
    width: 32%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    justify-content: space-between;

}
.titles{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pricebtns{
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        background: white;
        padding: 4px 8px;
        width: 120px;
        button{
            cursor: pointer;
            width: 50%;
            background: transparent;
            border-radius: 4px;
            color: var(--grey);
            // padding: 4px 8px;
        }
    }
}
@media only screen and(max-width:1700px){
    .body-table{
        overflow: scroll;
        max-height: 30vh;
    }
}
@media only screen and(max-width:1000px){
    .coinslist{
        width: 100%;
    }
    .pricebtns{
        width: 40% !important;
    }
}
</style>