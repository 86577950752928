<template>
    <div class="trading">
      <vue-trading-view
          :key="$route.params.tradeFrom"
          style="height: 100%"
          :options="{
              //symbol: 'BINANCE:' + $coinUnit[$route.params.tradeFrom] + 'USDT',
              symbol: $route.params.tradeFrom !== 'TETHER' ? 'BINANCE:' + $coinUnit[$route.params.tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
              height:'710',
              autosize:true,  
             theme:'light',  
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:true,
                      hide_bottom_toolbar:false,
                      hide_legend:false,
                      locale: this.state.lan=='Fa' ? 'fa_IR': 'en',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }"
      />
    </div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
export default {
  name: 'Tradingchart',
  components :{
    VueTradingView
  },
  props:['coinType'],
//   props:['selectedCoin'],
  data() {
      return {
         
      }
  },
  mounted () {
  }
  }
</script>

<style lang="scss" scoped>
    .trading {
        width: 67%;
    }
@media only screen and(max-width:1000px){
    .trading{
        width: 100%;
    }
}
</style>