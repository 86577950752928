<template>
    <div class="buy-conteiner">
        <ConfirmModal :key="price" v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="price"
                    :amount="amount" type="BUY" :date="eDate" :limit="marketClick" @confirm="accept" :tradeFrom="$route.params.tradeFrom" :tradeTo="$route.params.tradeTo" />
        <form @input="calculate" class="inputs">
            <div class="trade-input-container">
                <span class="input-title"> قیمت </span>
                <input :disabled="marketClick" @input="unitPrice = $toLocal(unitPrice,$decimal[$route.params.tradeTo])" v-model="unitPrice" type="text">
                <span class="price-type"> {{$coinLabel[$route.params.tradeTo]}} </span>
            </div>
            <div class="amount-fileds">
                <div class="balance">
                    <span class="balance-title"> موجودی </span>
                    <span class="balance-num"> {{$toLocal(credit,$decimal[$route.params.tradeTo])}} </span>
                    <span class="balance-title"> {{$coinLabel[$route.params.tradeTo]}}  </span>
                </div>
                <div class="trade-input-container">
                    <span class="input-title"> مقدار </span>
                    <input @input="amount = $toLocal(amount,$decimal[$route.params.tradeFrom])" v-model="amount" type="text">
                    <span class="price-type"> {{$coinLabel[$route.params.tradeFrom]}} </span>
                </div>
                <div class="range-input-container">
                    <input v-model="percent" min="0.1" max="100" type="range">
                    <div class="range-limits">
                        <span @click="percent=100"> 100 </span>
                        <span @click="percent=75"> 75 </span>
                        <span @click="percent=50"> 50 </span>
                        <span @click="percent=25"> 25 </span>
                        <span @click="percent=0"> 0 </span>
                    </div>
                </div>

            </div>
            <date-picker 
                        v-if="marketClick==false"
                        placeholder=" تاریخ انقضا " 
                        v-model="eDate"
                        locale="fa"
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['faDateTime']"
                        :clearable="true"
                        type="datetime"
                        :min="now"
                        class="date"/>
        </form>
        <div class="details">
            <div class="numbers">
                <div @click="showDetails = !showDetails" class="row total">
                    <span class="total-text"> مجموع {{$toLocal(totalPrice,$decimal[$route.params.tradeTo])}} تومان </span>
                    <div class="detaildrop">
                        <span> جزئیات </span>
                        <inline-svg :class="{'rotate':showDetails}" class="arrow arrowsvg"  :src="require('../../assets/icons/inputarrow.svg')" />
                    </div>
                </div>
                <div v-if="showDetails" class="row totalprice">
                    <span> بیشترین مقدار مجاز </span>
                    <div class="pricetag">
                        <span> {{$toLocal(max,$decimal[$route.params.tradeFrom])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeFrom]}} </span>
                    </div>
                </div>
                 <div v-if="showDetails" class="row totalprice">
                    <span> کمترین مقدار مجاز </span>
                    <div class="pricetag">
                        <span> {{$toLocal(min,$decimal[$route.params.tradeFrom])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeFrom]}} </span>
                    </div>
                </div>
                <div v-if="showDetails" class="row totalprice">
                    <span> مبلغ سفارش </span>
                    <div class="pricetag">
                        <span> {{$toLocal(price,$decimal[$route.params.tradeTo])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
                <div v-if="showDetails" class="row feeprice">
                    <span> کارمزد ({{'%'+wage}}) </span>
                    <div class="pricetag">
                        <span> {{  $toLocal(priceWithFee,$decimal[$route.params.tradeTo])  }} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
                <div v-if="showDetails" class="row recievedprice">
                    <span> پرداختی شما </span>
                    <div class="pricetag">
                        <span> {{$toLocal(totalPrice,$decimal[$route.params.tradeTo])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeTo]}} </span>
                    </div>
                </div>
            </div>

        </div>
        <button @click.prevent="show=true" :disabled="checkBtn" class="buy"> {{checkBtn ? checkBtn : 'خرید' +$coinLabel[$route.params.tradeFrom] }} </button>
    </div>
</template>

<script>
import {tradeTo, tradeFrom, precision, Loop} from "@/library/reuseableFunction";
import ConfirmModal from './ConfirmModal.vue';
    export default {
        name:'BuyCrypto',
        components:{
            ConfirmModal
        },
        props:['marketClick','role','credit','wage'],
        data() {
            return {
                show:false,
                eDate: '',
                percent:0,
                unitPrice: '',
                amount: '',
                totalPrice:0,
                price:0,
                showDetails:false,
                priceWithFee:0,
                loop:''
            }
        },
        watch: {
            'unitPrice'(){
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                }
            },
            'percent'(){
                this.changeRange();
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                    this.amount=0;
                    this.totalPrice=0;
                }
            },
            'amount'(){
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.amount=0;
                }
            },
            'marketClick'(){
                this.amount=''
                this.unitPrice=''
                this.totalPrice = 0
                this.priceWithFee=0
                this.price=0
                this.showDetails=false
            }
        },
        computed:{
            checkBtn(){
                let errors = ''
                if(!this.state.token){
                    errors = 'ابتدا وارد شوید'
                }else if(this.marketClick && this.$S2N(this.amount) && !this.$S2N(this.unitPrice)){
                    errors= 'مقدار مجاز نیست'
                }
                else if(!this.marketClick && !this.$S2N(this.unitPrice)){
                        errors= 'قیمت را وارد کنید'
                }
                else if(!this.$S2N(this.amount)){
                        errors= 'مقدار را وارد کنید'
                }
                else if((this.$S2N(this.price) > this.$S2N(this.credit)) & !this.state.userInfo.supervisor ){
                    errors= 'موجودی کافی نیست'
                }
                // else if(this.marketClick & this.state.userInfo.supervisor & (this.$S2N(this.totalPrice) > this.$S2N(this.credit))){
                //     errors= 'موجودی بازارگردان کافی نیست'
                // }
                else if(this.$S2N(this.amount) < this.min){
                    errors= 'کمتر از مقدار مجاز'
                }
                else if(this.$S2N(this.amount) > this.max){
                    errors= 'بیشتر از مقدار مجاز'
                } else{
                    errors=false;
                }
                return errors ;
            },
            now() {
                return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
            },
            min(){
                return this.role?.minAmount
            },
            max(){
                return this.role?.maxAmount
            },
            tradeTo, tradeFrom, precision
        },
        methods: {
            async accept() {
                this.state.loading = true;
                let orderDetail;
                let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

                if (!this.marketclick) {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `LIMITED_BUY`,
                        expiresAt: this.eDate || null,
                        unitPrice: this.$S2N(this.unitPrice),
                        wholePrice: this.$S2N(this.price),
                    }
                } else {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `MARKET_BUY`,
                    }
                }
                try {
                    const res = await this.$axios.post(`/orders/buy?force=${ this.force ? true: false}`, orderDetail)
                    if(res){
                        ++this.state.orderChanged;
                        this.$error('', 'سفارش با موفقیت ثبت شد', 'success');
                        this.force = false;
                    }
                } catch (error) {
                    this.state.loading = false
                    if (error.response.data.message === 'NOT_ACCEPTABLE_UNIT_PRICE'){
                        this.$swal.fire({
                                title: 'آیا مطمئن هستید',
                                text: "قیمت سفارش با قیمت میانگین "+ error.response.data.errors[1].replace('percent:','') + '%' +"متفاوت میباشد",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'ثبت',
                                cancelmButtonText: 'لغو'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.force =true;
                                    this.accept();
                                }
                                })
                    }
                    if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                        this.$error('', 'حجم درخواستی بیش از مقدار بازار است.');
                    }
                }
            },
            changeRange(){
                if (!this.marketClick) {
                    const up = this.$S2N(this.unitPrice)
                    let a = this.credit * (this.percent/100)
                    this.amount = a / up
                }else{
                    this.price =this.credit * (this.percent/100)
                }
                this.calculate();
            },
            changeRangeByAmount(){
                const up = this.$S2N(this.unitPrice)
                const a = this.$S2N(this.amount)
                let total = up*a
                this.percent = total / this.credit
            },
            changeMarketType(val){
                this.marketClick=val
                this.amount='';
                this.unitPrice='';
            },
            calculate() {
                if (!this.marketClick && this.unitPrice && this.amount) {
                    this.localCalculate()
                } else if (this.marketClick) {
                    this.serverCalculate()
                }
            },
            localCalculate() {
                const up = this.$S2N(this.unitPrice)
                const a = this.$S2N(this.amount)
                this.price = up*a
                this.priceWithFee = this.price * (this.wage/100)
                this.totalPrice = this.price + this.priceWithFee
            },
            async serverCalculate() {
                if(this.loop){
                    this.loop.stop()
                }
                if(!this.percent && !this.$A2N(this.amount)){
                    return false
                }else if (this.$A2N(this.amount) && this.marketClick) {
                    console.log('is amount',this.amount);
                    if(this.state.loop){
                        this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
                        // this.loop = new Loop(this.getAmountFromServer, this.state.time, 'getAmountViaPriceBuy')
                        this.loop.start()
                    }
                    else{
                        this.getPriceFromServer()
                    }
                }else if(!this.$A2N(this.amount) && this.marketClick){
                    console.log('no amount',this.amount);
                    if(this.state.loop){
                        // this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
                        this.loop = new Loop(this.getAmountFromServer, this.state.time, 'getAmountViaPriceBuy')
                        this.loop.start()
                    }else{
                        this.getAmountFromServer()
                    }
                }
            },
            async getAmountFromServer() {
                    const res = await this.$axios('/orders/amount-via-price', {
                        params: {
                            marketType: this.tradeFrom + '_' + this.tradeTo,
                            orderType: 'MARKET_BUY',
                            amount: this.price
                        }
                    })
                    if (res) {
                        // let a = res.baseDTO.wholePrice
                        this.amount = this.$toLocal(res.baseDTO.wholePrice, this.decimal)
                        this.priceWithFee = this.price * (this.wage/100)
                        this.totalPrice = this.price + this.priceWithFee
                        this.unitPrice = this.$toLocal(this.$A2N(this.price) / res.baseDTO.wholePrice,this.$decimal[this.$route.params.tradeTo])
                        console.log(this.$A2N(this.price));
                        console.log(this.unitPrice);
                    }
            },
            async getPriceFromServer() {
                if(this.marketClick){
                let a = this.$A2N(this.amount);
                const [res,] = await this.$http.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: a,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_BUY'
                    }
                })
                if (res) {
                    this.price = res.baseDTO.wholePrice
                    this.priceWithFee = this.price * (this.wage/100)
                    this.totalPrice = this.price + this.priceWithFee
                    this.unitPrice = this.$toLocal((this.$S2N(this.price) / a), this.$decimal[this.$route.params.tradeTo]);
                }
                this.calculating = false
            }
            },
        },
        beforeDestroy() {
            if(this.loop){
                    this.loop.stop()
            }
        },
    }
</script>

<style lang="scss" scoped>
input:disabled{
    opacity: .7 !important;
}
.buy{
    padding: 8px 0px;
    border-radius: 4px;
    background: var(--green) !important;
    color: white !important;
}
.rotate{
    transform: rotate(-180deg) !important;
}
.details{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    .numbers{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .total-text{
            word-spacing: 5px;
        }
        .detaildrop{
            cursor: pointer;
            transition: 300ms;
            &:hover{
                color: var(--primary-color);
                .arrowsvg{
                    fill: var(--primary-color) !important;
                }
            }
            .arrow{
                transform: rotate(0deg);
                transition: 500ms;
            }
        }
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .pricetype{
            color: var(--grey);
        }
    }
}
.inputs{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    .range-input-container{
        display: flex;
        flex-direction: column;
        padding: 8px 12px;
        background: var(--light-grey);
        border-radius: 4px;
        input{
            direction: ltr !important;
        }
        input {
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        border-radius: 5px;  
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: .2s;
        transition: opacity .2s;
        }

        input::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 15px;
        border-radius: 10%; 
        background: white;
        cursor: pointer;
        }

        input::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #04AA6D !important;
        cursor: pointer;
        }
        .range-limits{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            span{
                cursor: pointer;
            }
        }
    }
    .trade-input-container{
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        background: var(--light-grey);
        border-radius: 4px;
        span{
            font-size: clamp(10px,1vw,14px);
        }
        .price-type{
            color: var(--grey);
        }
        input{
            width: 70%;
            background: transparent;
        }
    }
    .amount-fileds{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
    .balance{
        display: flex;
        flex-direction: row;
        column-gap: 4px;
        align-items: center;
        justify-content: flex-end;
        .balance-num{
            font-size: clamp(11px,1vw,13px);
        }
    }
    .balance-title{
        color: var(--grey);
        font-size: clamp(10px,1vw,12px);
    }
}




.go-left{
    left: 10px;
}
.go-right{
    right: 10px;
}
.buy-conteiner{
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    width: 100%;
}
.select-order{
    display: flex;
    width: 100%;
    background: var(--light-grey);
    border-radius: 0px 0px 8px 8px;
}
.btns{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
    padding: 6px;
    .underline{
        bottom: 7px;
        position: absolute;
        border-radius: 12px;
        width: 20px;
        height: 3px;
        background: var(--green);
    }
    button{
        color: var(--grey);
        font-size: clamp(12px,1vw,14px);
    }
    .active-btn{
        color: var(--black-fee) !important;
    }
}
@media only screen and(max-width:400px){

    .total{
        flex-direction: column !important;
        row-gap: 10px;
    }

}
</style>