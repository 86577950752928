var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trading"},[_c('vue-trading-view',{key:_vm.$route.params.tradeFrom,staticStyle:{"height":"100%"},attrs:{"options":{
          //symbol: 'BINANCE:' + $coinUnit[$route.params.tradeFrom] + 'USDT',
          symbol: _vm.$route.params.tradeFrom !== 'TETHER' ? 'BINANCE:' + _vm.$coinUnit[_vm.$route.params.tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
          height:'710',
          autosize:true,  
         theme:'light',  
                  timezone: 'Asia/Tehran',
                  style: '1',
                  hide_top_toolbar:true,
                  hide_legend:true,
                  hide_bottom_toolbar:false,
                  hide_legend:false,
                  locale: this.state.lan=='Fa' ? 'fa_IR': 'en',
                  enable_publishing: false,
                  withdateranges: true,
                  range: 'ytd',
                  hide_side_toolbar: true,
                  allow_symbol_change: true,
                  enabled_features: [
                      'header_fullscreen_button'
                      ],
                  disabled_features: [
                      'border_around_the_chart',
                      'remove_library_container_border' ],
                  loading_screen:{
                      backgroundColor:'#F2F6FF',
                      foregroundColor: '#F2F6FF',
                      },
                  overrides: {
                      background: '#F2F6FF'
                  }
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }