<template>
    <div :class="{'pad-top':!state.token}" class="shop">
        <div class="btns">
            <button @click="buySelected=true" :class="{'buy-active':buySelected}" class="buy"> خرید </button>
            <button @click="buySelected=false" :class="{'sell-active':!buySelected}" class="sell"> فروش </button>
        </div>
        <div class="shopitem-container">
            <div class="select-order">
                <div class="select-btns">
                    <button :class="{'active-btn':!marketClick}" @click="changeMarketType(false)" class="limit"> حد </button>
                    <button :class="{'active-btn':marketClick}" @click="changeMarketType(true)" class="market"> بازار </button>
                    <div :class="marketClick ? 'go-left':'go-right'" class="underline"></div>
                </div>
            </div>
            <BuyCrypto :role="role" :credit="buyCredit" :wage="state.userInfo.fee" :marketClick.sync="marketClick" v-if="buySelected" />
            <SellCrypto :role="role" :credit="sellCredit" :wage="state.userInfo.fee" :marketClick.sync="marketClick" v-if="!buySelected" />
        </div>
    </div>
</template>

<script>
import BuyCrypto from './BuyCrypto.vue';
import SellCrypto from './SellCrypto.vue';
import {tradeTo,tradeFrom} from "@/library/reuseableFunction";
    export default {
        name:'Shop',   
        components:{
            BuyCrypto,
            SellCrypto
        },
        computed:{
            tradeTo,tradeFrom,
        },
        data() {
            return {
                marketClick:false,
                buySelected:true,
                sellCredit: 0,
                buyCredit: 0,
                coins:[],
                role:{},
            }
        },
        methods: {
            changeMarketType(val){
                this.marketClick=val
            },
            async getWalletCredit() {
                if (this.state.userInfo.authorized) {
                    const res = await this.$axios.get('/wallets/customer-addresses', {
                        params: {
                            coins: `${this.tradeFrom},${this.tradeTo}`,
                            walletType:'P2P'
                        }
                    })
                    this.buyCredit = res[this.tradeTo].credit || 0
                    this.sellCredit = res[this.tradeFrom].credit || 0
                }
                const coins = await this.$axios.get('/coins')
                this.role = coins?.content?.find((a) => a.coin === this.tradeFrom)
            },
        },
        mounted() {
            this.getWalletCredit();
        },
        beforeDestroy() {
            if(this.loop){
                    this.loop.stop()
            }
        },
    }
</script>

<style lang="scss" scoped>
.pad-top{
    padding-top: 120px;
}
.shopitem-container{
    display: flex;
    flex-direction: column;
    row-gap: 36px;
}
.go-left{
    left: 10px;
}
.go-right{
    right: 10px;
}
.select-btns{
    position: relative;
    display: flex;
    flex-direction: row;
    width: max-content;
    gap: 40px;
    // justify-content: space-between;
    padding: 6px;
    .underline{
        bottom: 0px;
        position: absolute;
        border-radius: 12px;
        width: 20px;
        height: 3px;
        background: var(--green);
    }
    button{
        color: var(--grey);
        font-size: clamp(12px,1vw,14px);
    }
    .active-btn{
        color: var(--black-fee) !important;
    }
}
.select-order{
    display: flex;
    width: 100%;
    background: var(--light-grey);
    // border-radius: 8px 8px 0px 0px;
    transform: translateY(-5px);
}







.shop{
    padding: 16px;
    display: flex;
    flex-direction: column;
}
.btns{
    width: 100%;
    z-index: 3;
    button{
        width: 50%;
        border-radius: 4px;
        padding: 5px;
    }
    .sell{
        background: rgba(226, 203, 203, 1);
        color: rgba(177, 153, 153, 1);
        border-radius:4px 0 0 4px;
    }
    .buy-active{
        border: 2px solid rgba(33, 163, 21, 1);
        background: rgba(226, 255, 218, 1);
        color: rgba(33, 163, 21, 1) !important;
        border-radius: 4px !important;
        transform: scale(1,0.88);
    }
    .buy{
        background: rgba(178, 227, 174, 1);
        color: rgba(113, 178, 107, 1);
        border-radius:0 4px 4px 0 ;
    }
    .sell-active{
        border-radius: 4px !important;
        border: 2px solid rgba(227, 13, 13, 1);
        background: rgba(255, 228, 228, 1);
        color: rgba(227, 13, 13, 1) !important;
        transform: scale(1,0.88);

    }
}
@media only screen and(max-width:1300px){
    .shop{
        width: 100%;
        max-width: 500px;
        background: white;
        border-radius: 12px;
    }
}
</style>