<template>
    <div class="defth-parent">
        <div class="buysell-column">
            <div class="radius-top-right header-table">
                <span style="width:80px"> مجموع </span>
                <span> مقدار </span>
                <span> قیمت فروش </span>
            </div>
            <div class="sell-line"></div>
            <div class="body-table">
                <div :style="'background: linear-gradient(to right,rgba(255, 161, 161, 1) '+trade.executedPercent+'%,white 0%)'" v-for="(trade,index) in tableContentsSell" :key="index" class="coin-row buy">
                    <span> {{ $toLocal(trade.wholePrice,$decimal[$route.params.tradeTo]) }} </span>
                    <span> {{ $toLocal(trade.volume,$decimal[$route.params.tradeFrom]) }} </span>
                    <span class="fail"> {{ $toLocal(trade.unitPrice,$decimal[$route.params.tradeTo]) }} </span>
                </div>
            </div>
        </div>
        <div class="average-column">
            <div class="avr-header">

            </div>
            <div style="background: linear-gradient(to right,rgba(33, 163, 21, 1) 50%,rgba(227, 13, 13, 1) 50%)" class="avr-line"></div>
            <div class="avr-body">
                <span class="lastprice-text"> آخرین قیمت </span>
                <span class="lastprice-usdt">{{state.shopInfo.lastTrade?$toLocal(state.shopInfo.lastTrade,$decimal[$route.params.tradeTo]):0}}</span>
                <span> {{$route.params.tradeTo == 'TETHER' ? 'USDT' : 'تومان'}} </span>
            </div>
        </div>
        <div class="buysell-column">
            <div class=" radius-top-left header-table">
                <span style="width:80px"> مجموع </span>
                <span> مقدار </span>
                <span> قیمت فروش </span>
            </div>
            <div class="buy-line"></div>
            <div class="body-table">
                <div :style="'background: linear-gradient(to right,rgba(167, 255, 159, 1)'+ trade.executedPercent+ '%,white 0%)'"  v-for="(trade,index) in tableContentsBuy" :key="index" class="coin-row sell">
                    <span> {{ $toLocal(trade.wholePrice,$decimal[$route.params.tradeTo]) }} </span>
                    <span> {{ $toLocal(trade.volume,$decimal[$route.params.tradeFrom]) }} </span>
                    <span class="success"> {{ $toLocal(trade.unitPrice,$decimal[$route.params.tradeTo]) }} </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {Loop} from '../../library/reuseableFunction';
export default {
    name:'Defth',
    data() {
        return {
            lastPriceTether:'',
            lastPriceToman:'',
            loop:'',
            average:1258473000,
            tableContentsSell:[],
            tableContentsBuy:[],
        }
    },
    methods: {
            sort(data) {
                data.sort((a, b) => a - b)
            },
            async getMarketDepth() {
                    const [res,] = await this.$http.get('/orders/order-book', {
                        params: {
                            marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                        }
                    })
                    if(res){
                        this.tableContentsSell = res.sellOrderBook
                        this.tableContentsBuy = res.buyOrderBook
                        this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                        this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
                    }
                    this.loading=false
            },
    },
    mounted() {
        this.loading = true
            if (this.state.loop) {
                this.loop = new Loop(this.getMarketDepth,this.state.time,'marketDepth:'+this.tradeFrom+'-'+this.tradeTo)
                this.loop.start()
            } else {
                this.getMarketDepth()
            }
    },
    beforeDestroy() {
      if(this.loop){
                    this.loop.stop()
            }
    },
}
</script>

<style lang="scss" scoped>
.radius-top-left{
    border-top-left-radius: 12px;
}
.radius-top-right{
    border-top-right-radius: 12px;
}
.header-table , .avr-header{
    height: 42px;
}
.average-column{
    display: flex;
    flex-direction: column;
    width: 20%;
    .avr-header{
        background:white;
    }
    .avr-line{
        width: 100%;
        height: 4px;
        // margin-top: 3px;
        // padding: 1px 0;
    }
    .avr-body{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        align-items: center;
        padding: 8px;
        height: 100%;
        background: white;
        color: var(--grey);
        .lastprice-text{
            font-weight: 400;
            font-size: clamp(12px,1vw,14px);
        }
        .lastprice-toman{
            font-weight: 400;
            font-size: clamp(16px,1vw,18px);
        }
        .lastprice-usdt{
            font-weight: 700;
            font-size: clamp(16px,2vw,18px);
            color: var(--black-fee) !important;
        }
    }
}
.sell-line{
    background-color:var(--red);
    border-radius: 0px 4px 4px 0px ;
    height: 4px;
}
.buy-line{
    background-color:var(--green);
    border-radius: 20px;
    border-radius: 4px 0px 0px 4px ;
    height: 4px;
}
.buysell-column{
    width: 45%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    .header-table{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background: white;
        padding: 12px 12px 4px;
        font-size: clamp(12px,1vw,14px);
        color: var(--grey);
    }
    .body-table{
        display: flex;
        flex-direction: column;
        width: 100%;
        background: white;
        padding: 12px 12px 4px;
        font-size: clamp(10px,1vw,12px);
        height: 100%;
        overflow: scroll;
        color: var(--grey);
        .coin-row{
            min-height: 30px;
            max-height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }
    }
}
.defth-parent{
    width: 67%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    max-height: 300px;
}
@media only screen and(max-width:1000px){
    .defth-parent{
        width: 100%;
    }
}
@media only screen and(max-width:700px){
    .defth-parent{
        width: 100%;
        flex-direction: column;
        row-gap: 12px;
    }
    .average-column{
        display: none;
    }
    .buysell-column{
        width: 100%;
    }
}
</style>