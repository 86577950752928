<template>
    <div :class="{'dash-nav':$route.name=='Dashboard'}" class="shopnav">
        <div @click="$router.push({name:'Notifications'})" class="notif-container">
            <inline-svg :src="require('../../assets/icons/sidebarNotifs.svg')" alt="" class="notification" />
        </div>
        <div @click="dropToggle = !dropToggle" class="profile">
            <inline-svg class="arrowsvg" :src="require('../../assets/icons/inputarrow.svg')" />
            <img src="../../assets/icons/avatar.svg" alt="">
        </div>
        <transition name="drop">
            <div v-if="dropToggle" class="drop-down">
                <a @click.prevent="reD(item.address)" v-for="(item,index) in dropItems" :key="index" href="" class="item"> {{item.name}} </a>
                <a @click.prevent="$logout" href="" class="item"> خروج </a>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name:'ShopNav',
        data() {
            return {
                dropToggle:false,
                dropItems:[
                    {
                        name:'داشبورد',
                        address:'Dashboard',
                    },
                    {
                        name:'کیف پول',
                        address:'Wallet',
                    },
                    {
                        name:'حساب کاربری',
                        address:'Account',
                    },
                    {
                        name:'دعوت از دوستان',
                        address:'InviteFriends',
                    },
                ]
            }
        },
        methods: {
            reD(e){
                this.$router.push({name:e})
            }
        },
        created:function () {
            window.addEventListener("click", event => {
                if(!event.target.closest('.profile')){
                    this.dropToggle=false
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.dash-nav{
    justify-content: space-between !important;
}
.notification{
    fill: var(--grey);
}
.profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
}
.notif-container{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: var(--light-grey);
    border-radius: 4px;
    width: 36px;
    height: 36px;
}
.shopnav{
    position: relative;
    padding: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-end;
    z-index: 108;
    .drop-down{
        overflow: hidden;
        position: absolute;
        top: 60px;
        left: 0;
        width: 150px;
        background: white;
        border-radius: 12px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .item{
            background: var(--home-background);
            border-radius: 4px;
            padding: 5px;
            width: 100%;
            text-align: center;
            color: var(--grey);
            font-weight: 400;
            font-size: clamp(11px,1vw,14px);
        }
    }
}
@media only screen and(max-width:1300px){
    .shopnav{
        max-width: 500px;
        display: none;
    }
}
</style>