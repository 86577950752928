<template>
    <div :class="{'pad-top':!state.token}" class="dashboard-container">
    <SideBar v-if="state.token" />
        <div class="headet-tabs">
            <div v-if="state.token" class="navbar">
                            <a @click.prevent="$router.push({name:'Market'})" href=""> بازار </a>
                            <a @click.prevent="$router.push({name:'Faq'})" href=""> راهنمای استفاده </a>
                            <a @click.prevent="$router.push({name:'Fee'})" href=""> کارمزد ها </a>
            </div>
            <CoinHeader :key="$route.params.tradeTo + $route.params.tradeFrom + 'wt'" />
        </div>
        <div class="column-container">  
            <div class="right-column">
                <div class="content-container">
                    <div class="market-view">
                        <CoinList :key="$route.params.tradeTo + $route.params.tradeFrom + 'cl'" />
                        <TradingView :key="$route.params.tradeTo + $route.params.tradeFrom + 'tr'" />
                    </div>  
                    <div class="trades-defth">
                        <LastTrades :key="$route.params.tradeTo + $route.params.tradeFrom + 'lt'"  />
                        <Defth :key="$route.params.tradeTo + $route.params.tradeFrom + 'df'" />
                    </div>
                    <HistoryP2P v-if="state.token" />
                </div>
            </div>
            <div class="left-column">
                <ShopNav v-if="state.token" />
                <Shop :key="$route.params.tradeTo+$route.params.tradeFrom+'shop'" />
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '../Public/SideBar.vue';
import CoinHeader from './CoinHeader.vue';
import TradingView from './TradingView.vue';
import CoinList from './CoinList.vue';
import LastTrades from './LastTrades.vue';
import Defth from './Defth.vue';
import HistoryP2P from './HistoryP2P.vue'
import ShopNav from './ShopNav.vue';
import Shop from './Shop.vue'
export default {
    name:'P2PContainer',
    components:{
        SideBar,
        CoinHeader,
        TradingView,
        CoinList,
        LastTrades,
        Defth,
        HistoryP2P,
        ShopNav,
        Shop
    }
}
</script>
<style lang="scss" scoped>
.pad-top{
    padding-top: 75px;
}
.market-view{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 38%;
}
.trades-defth{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-height: 30%;
}
.content-container{
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    // padding:0 8px;
    padding-right: 95px;
    padding-left: 20px;
    background: var(--home-background);
    width: 100%;
    height: 100%;
    row-gap: 8px;
}
.right-column{
    width: 80%;
    display: flex;
    flex-direction: row;
    height: 100%;
}
.left-column{
    left: 0;
    top: 0;
    background: white;
    position: absolute;
    width: 20%;
    display: flex;
    flex-direction: column;
}
.dashboard-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .headet-tabs{
        background: var(--home-background);
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 95px 10px 40px;
        .navbar{
        display: flex;
        flex-direction: row;
        column-gap: 40px;
        padding: 18px;
        a{
            color: var(--grey);
            font-weight: 400;
            font-size: clamp(11px,1vw,14px);
        }
    }
    }
}
.column-container{
    display: flex;
    flex-direction: row;
    width: 100%;
}
@media only screen and(max-width:1300px){
    .left-column{
        position: unset !important;
        background: var(--home-background) !important;
    }
    .column-container{
        flex-direction: column;
        row-gap: 20px;
        background: var(--home-background);
    }
    .right-column{
        width: 100%;
    }
    .left-column{
            order: -1;
            width: 100%;
            padding: 0px 40px;
            align-items: center;
            padding-right: 120px;
    }
    .headet-tabs{
        padding: 10px 120px 10px 10px  !important;
    }
}
@media only screen and(max-width:1000px){
    .market-view{
        flex-direction: column;
        row-gap: 12px;
        .coinslist{
            order: 2;
        }
    }
    .trades-defth{
        flex-direction: column;
        row-gap: 12px;
    }
}
@media only screen and(max-width:600px){
    .left-column{
        padding: 0 75px 0px 0px;
    }
    .headet-tabs{
        padding: 10px 85px 10px 10px  !important;
    }
    .content-container{
        padding-right: 70px;
    }
    .navbar{
        column-gap: 10px !important;
    }
}
</style>